2<template>
  <Login class="login" :redirectParams="redirectParams" />
</template>
<script>
import Login from "@/components/Login.vue";
export default {
  name: "LoginView",
  components: {
    Login
  },
  props: {
    redirectParams: { type: Object, required: false }
  }
};
</script>
